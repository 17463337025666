document.addEventListener('turbolinks:load', () => {
  const $modalButtons = Array.prototype.slice.call(document.querySelectorAll('[data-toggle="xhr-modal"]'), 0)
  const $body = document.getElementsByTagName('body')[0]

  if ($modalButtons.length > 0) {
    const $modal = document.getElementById('xhrModal')

    $modalButtons.forEach(el => {
      el.addEventListener('click', (event) => {
        event.preventDefault()

        const href = el.getAttribute('href')

        $.ajax({
          url: href,
          method: 'GET',
          success: function (response) {
            $modal.querySelector('.modal-content').innerHTML = response
            $modal.classList.add('is-active')
            $body.classList.add('is-clipped')
          }
        })
      })
    })
  }
})
